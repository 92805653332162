// eslint-disable-next-line import/prefer-default-export
import React from "react";
import { Link } from "gatsby";

export const reportingHero = {
  title: "Work smarter with better reporting",
  subtext:
    "Know what’s selling and what’s not, so you can make better decisions to run and grow your business.",
  heroImg: "reporting-hero.png",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/products/reporting/demo",
  },
  fatTitle: "Reporting",
};

export const reportingTrusted = [
  {
    title: "300m+",
    subtext: "Marketing campaigns",
  },
  {
    title: "5m+",
    subtext: "Loyalty redemptions",
  },
  {
    title: "2,000+",
    subtext: "New businesses each month",
  },
  {
    title: "7+",
    subtext: "Million consumers",
  },
];

export const reportingWhiteGlove = {
  mainTitle: "We are there for you & your customers",
  title: "Personalized setup and a data guru on standby",
  content:
    "Good data doesn’t just appear out of thin air. Our team will work closely with you to set up a custom point-of-sale and software solution that not only helps you save time and money, but also works double-time to collect valuable data for you. We’ll then make sure that your data is easy to understand with cloud-based reporting you can access anywhere, anytime. ",
  ctaInfoPrimary: {
    ctaTitle: "Learn more",
    ctaTarget: "/products/reporting/demo",
  },
};

export const reportingLargeFeatures = {
  sectionTitle: "Get the data you need, when you need it",
  featureBlocks: [
    {
      blockTitle: "Dashboard analytics",
      blockSubTitle:
        "SpotOn gives you powerful tools to stay connected with your customers, including integrated payments, digital marketing, review management, and optional loyalty rewards. Log in to your SpotOn Dashboard anytime from your computer or phone to monitor your business.",
      blockImg: "reporting-a.png",
      blockList: [
        "Sales trends over time",
        "Marketing campaign metrics",
        "ROI on reward redemptions",
        "Review requests sent to customers",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/reporting/demo",
      },
    },
    {
      blockTitle: "Best-in-class restaurant reporting",
      blockCustomSub: `With the <a href='/restaurant-pos' class="underline">SpotOn Restaurant</a> point-of-sale system, you get best-in-class reporting that’s available in the cloud, so you can access real-time data, even when you’re offsite. We’ll also save you thousands compared to a third-party software integration.`,
      blockImg: "reporting-b.png",
      blockList: [
        "90+ fully-interactive reports",
        "Easy to find and understand",
        "Manager alerts & subscriptions",
        "Mobile reporting app",
        "Enterprise reporting",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/reporting/demo",
      },
    },
    {
      blockTitle: "All retail reporting. All in one place",
      blockCustomSub: `With <a href='/retail' class="underline">SpotOn Retail</a>, you can bring together your in-store and online data so you can get comprehensive sales reports and make better decisions for your business.`,
      blockImg: "reporting-c.png",
      blockList: [
        "Track sales by channel, item, or staff member",
        "Get sales trends and your sales summary",
        "Filter by pay type, date range, and total",
        "Manage deposits and prepayments",
        "Handle disputes and chargebacks",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/reporting/demo",
      },
    },
  ],
};

export const reportingBusinessTypesCar = {
  title: "For businesses like yours",
  subtext: "We bring you better data, no matter what type of business you run.",
  slides: [
    {
      bg: "restaurant.png",
      cardTitle: "Restaurants",
      cardContent: "Fine Dining, Casual Dining, QSR, Food Stores, Food Trucks",
      cardIcon: "cart.svg",
      icon: "restaurant.png",
      linkTo: "/restaurants",
    },
    {
      bg: "auto.png",
      cardTitle: "Automotive",
      cardContent:
        "Oil Change Shops, Auto Parts, Auto Body, Repair, Tire Shops, Tint Shops",
      icon: "automotive.png",
      linkTo: "/automotive",
    },
    {
      bg: "beauty.png",
      cardTitle: "Health & beauty",
      cardContent:
        "Nail/Hair Salons, Tanning Salons, Barbers, Dentists, Veterinarian",
      icon: "health-and-beauty.png",
      linkTo: "/health-and-beauty",
    },
    {
      bg: "professional.png",
      cardTitle: "Professional Services",
      cardContent:
        "Lawyer, Accountant, Tow Truck, Architect, Plumber, Appraiser",
      icon: "prof-services.png",
      linkTo: "/professional-services",
    },
  ],
};

export const reportingVideo = {
  title: "See SpotOn in action",
  videoBtn: "Watch video",
  posterImg: "terminal-poster.png",
  videoUrlId: "cizH2XLJkeE",
};

export const reportingSolutionsSection = {
  title: "More great tools to run your business",
  subText: `Save time and grow revenue with integrated SpotOn technology that makes sense for your business.`,
  SecondaryCta: {
    ctaTitle: "View all",
    ctaTarget: "/page-2",
  },
};

export const reportingTestimonials = {
  title: "What other business owners are saying",
  reviews: [
    {
      quote:
        "From the initial consultation, to ordering, to implementation and integration, the entire process of on-boarding the SpotOn POS System has been seamless and has exceeded our expectations.",
      reviewDate: "Mar 9, 2021",
      reviewer: "Pete M.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn continues to be an invaluable technology partner that listens well and proactively presents innovative solutions to drive our business forward.",
      reviewDate: "Feb 9, 2021",
      reviewer: "Ben P.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn has given me the data access I need to manage my business from a macro as well as micro perspective. The software is easy to access and utilize.",
      reviewDate: "Dec 5, 2020",
      reviewer: "Pamela H.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "Every issue and question has been answered or addressed within hours, if not minutes.I can't say enough good about this company and software.",
      reviewDate: "Feb 24, 2021",
      reviewer: "Brandy R.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "From back office, digital marketing, built our website to take orders off of. The programming is great to allow you to do more tasks with better trained staff.",
      reviewDate: "Dec 1, 2020",
      reviewer: "Marcus O.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
  ],
};

export const reportingIntegrations = {
  title: "Integrations",
  subtext:
    "Sync all kind of third party applications and service with SpotOn and automate your revenue growth, retention and marketing processes.",
  integrationsContent: [
    {
      title: "Compatible with SpotOn Register.",
      subtext:
        "Run and grow your business more efficiently than ever before with our cutting-edge point-of-sale solution.",
      contentImg: "register.png",
      ImgClassName: "register",
      btnInfo: {
        ctaTitle: "How it Works",
        ctaTarget: "/page-2",
      },
    },
    {
      title: "Out of the box Stripe integration.",
      subtext:
        "Accept payments and move money globally with Stripe’s powerful APIs and software solutions",
      contentImg: "phone.png",
      ImgClassName: "phone",
      btnInfo: {
        ctaTitle: "Read More",
        ctaTarget: "/page-2",
      },
    },
  ],
};

export const reportingCTA = {
  title: "Sign up for a free demo.",
  ctaImg: "cta-people.png",
  ctaInfo: {
    ctaTitle: "Learn more",
    ctaTarget: "/products/reporting/demo",
  },
};

export const reportingFaqs = {
  title: "Frequently Asked Questions.",
  ctaInfo: {
    ctaTitle: "View more",
    ctaTarget: "/page-2",
  },
  list: [
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
  ],
};

export const reportingSolutionsSlides = [
  {
    title: "Register",
    link: "/products/register",
    img: "Register.png",
  },
  {
    title: "Appointments",
    img: "Appointments1.png",
    link: "/products/appointments",
  },
  {
    title: "Reserve",
    link: "/products/reserve",
    img: "Reserve.png",
  },
  {
    title: "Loyalty",
    link: "/products/loyalty",
    img: "Loyalty.png",
  },
  {
    title: "Restaurants",
    link: "/restaurants",
    img: "Restaurant.png",
  },
  {
    title: "Terminal",
    link: "/products/terminal",
    img: "Terminal.png",
  },
];
