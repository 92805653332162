import React from "react";
import loadable from "@loadable/component";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import {
  reportingHero,
  reportingTrusted,
  reportingWhiteGlove,
  reportingLargeFeatures,
  // reportingBusinessTypesCar,
  // reportingVideo,
  reportingSolutionsSection,
  reportingSolutionsSlides,
  reportingTestimonials,
  // reportingIntegrations,
  reportingCTA,
  // reportingFaqs,
} from "../../../data/product-pages/reporting-data";
import { temakiSlides, heroSuccess } from "../../../data/success-stories-data";
import Hero from "../../../components/Hero/hero";
// import TrustedNumbers from "../../../components/TrustedNumbers/TrustedNumbers";
// import WhiteGlove from "../../../components/WhiteGlove/WhiteGlove";
// import LargeFeatures from "../../../components/LargeFeatures/LargeFeatures";
// import SuccessStories from "../../../components/SuccessStories/SuccessStories";
// import PartnerLogos from "../../../components/PartnerLogos/PartnerLogos";
// import TestmonialReviews from "../../../components/TestimonialReviews/TestmonialReviews";
// import LargeCta from "../../../components/CTAs/LargeCta";
// import ProductLines from "../../../components/ProductLines/ProductLines";
import ogImage from "../../../images/global_assets/og-image.png";

const TrustedNumbers = loadable(() =>
  import("../../../components/TrustedNumbers/TrustedNumbers")
);
const WhiteGlove = loadable(() =>
  import("../../../components/WhiteGlove/WhiteGlove")
);
const LargeFeatures = loadable(() =>
  import("../../../components/LargeFeatures/LargeFeatures")
);
const SuccessStories = loadable(() =>
  import("../../../components/SuccessStories/SuccessStories")
);
const PartnerLogos = loadable(() =>
  import("../../../components/PartnerLogos/PartnerLogos")
);
const TestmonialReviews = loadable(() =>
  import("../../../components/TestimonialReviews/TestmonialReviews")
);
const LargeCta = loadable(() => import("../../../components/CTAs/LargeCta"));
const ProductLines = loadable(() =>
  import("../../../components/ProductLines/ProductLines")
);

const whiteGloveImport = "reporting-white-glove.png";

const Reporting = () => {
  return (
    <Layout>
      <SEO
        title="Restaurant Reporting and Analysis | SpotOn"
        description="Our POS system includes restaurant reporting & analysis. Stay connected with customers,  sales trends over time, ROI on reward redemptions, & more. Get a demo!"
        image={`https://spoton.com/${ogImage}`}
        cannonical="https://www.spoton.com/products/reporting/"
      />
      <Hero sectionData={reportingHero} circleBg={false} />
      <TrustedNumbers numbersArray={reportingTrusted} />
      <WhiteGlove
        sectionData={reportingWhiteGlove}
        whiteGloveBg={whiteGloveImport}
      />
      <LargeFeatures
        sectionData={reportingLargeFeatures}
        style={{ marginTop: "128px" }}
      />
      {/*
      <BusinessTypes sectionData={reportingBusinessTypesCar} />
*/}
      <SuccessStories sectionData={heroSuccess} slides={temakiSlides} />
      {/* <VideoSection sectionData={reportingVideo} /> */}
      <PartnerLogos />
      <ProductLines
        sectionData={reportingSolutionsSection}
        productSlides={reportingSolutionsSlides}
      />
      <TestmonialReviews sectionData={reportingTestimonials} />
      <PartnerLogos />
      {/* <IntegrationsSection
        sectionData={reportingIntegrations}
        integrationList={restaurantData}
      /> */}
      <LargeCta sectionData={reportingCTA} />
      {/* <Faqs sectionData={reportingFaqs} /> */}
    </Layout>
  );
};

export default Reporting;
